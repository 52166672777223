<template>
    <div>
        <aside-comp v-if="showAside" @closeAside="showAside = false" v-click-outside="onClickOutside" />
        <header class="fixed w_100 aplayz_header">
            <ul class="aplayz_header_pc">
                <li class="align_center">
                    <img class="pc_logo" src="/media/img/logo_white.svg" width="120" height="40" alt="로고" />
                    <svg class="mo_logo" width="70" height="16" viewBox="0 0 70 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18.8189 0.5H13.9541V15.5H17.2903V10.5206H18.9114C21.8377 10.5206 24.3901 8.9483 24.3901 5.38027C24.3937 1.66873 21.8746 0.5 18.8189 0.5ZM18.7466 7.6757H17.2932V3.34173H18.6533C20.2556 3.34173 21.15 3.86585 21.15 5.3779C21.1472 6.85031 20.3655 7.6757 18.7437 7.6757H18.7466Z"
                            fill="white"
                        ></path>
                        <path d="M29.3226 0.5H25.9863V15.5H34.7087V12.4759H29.3233L29.3226 0.5Z" fill="white"></path>
                        <path d="M53.1435 6.75753H53.0712C52.68 5.5888 50.7979 0.5 50.7979 0.5H47.2549L51.4291 10.1963V15.5H54.7458V10.1979L58.9396 0.501573H55.4552C55.4552 0.501573 53.5347 5.5888 53.1435 6.75753Z" fill="white"></path>
                        <path d="M63.9621 12.4743L69.944 2.65668V0.5H60.44V3.48287H65.8442L59.8623 13.3203V15.5H69.9997V12.4759L63.9621 12.4743Z" fill="white"></path>
                        <path d="M4.30513 0.5L0 15.5H3.39118L6.18728 3.59863L9.094 15.5H12.5973L8.2914 0.5H4.30513Z" fill="white"></path>
                        <path
                            d="M6.29928 12.2598C6.00818 12.2643 5.7248 12.3631 5.48477 12.5438C5.24473 12.7244 5.05876 12.9789 4.95021 13.2751C4.84167 13.5713 4.81541 13.8961 4.87472 14.2086C4.93404 14.5212 5.07629 14.8075 5.28359 15.0317C5.49089 15.2558 5.754 15.4077 6.03982 15.4684C6.32565 15.529 6.62145 15.4956 6.89002 15.3724C7.1586 15.2492 7.38796 15.0417 7.5493 14.776C7.71063 14.5102 7.79672 14.1982 7.79675 13.8789C7.79542 13.6648 7.75563 13.453 7.67965 13.2557C7.60367 13.0584 7.49298 12.8795 7.35392 12.7291C7.21486 12.5788 7.05014 12.4599 6.86918 12.3794C6.68822 12.2989 6.49456 12.2582 6.29928 12.2598Z"
                            fill="white"
                        ></path>
                        <path d="M40.5857 0.5L36.2812 15.5H39.6732L42.4685 3.59863L45.3752 15.5H48.8792L44.5741 0.502371L40.5857 0.5Z" fill="white"></path>
                        <path
                            d="M42.5813 12.2598C42.2901 12.2642 42.0067 12.3629 41.7665 12.5434C41.5264 12.7239 41.3403 12.9783 41.2317 13.2745C41.123 13.5708 41.0966 13.8956 41.1559 14.2082C41.2151 14.5208 41.3573 14.8072 41.5646 15.0314C41.7719 15.2556 42.035 15.4076 42.3208 15.4683C42.6067 15.529 42.9025 15.4957 43.1711 15.3725C43.4397 15.2493 43.6691 15.0418 43.8305 14.7761C43.9919 14.5103 44.078 14.1982 44.078 13.8789C44.0768 13.6648 44.0371 13.453 43.9612 13.2558C43.8853 13.0585 43.7746 12.8796 43.6356 12.7292C43.4966 12.5788 43.332 12.46 43.151 12.3794C42.9701 12.2989 42.7765 12.2582 42.5813 12.2598Z"
                            fill="white"
                        ></path>
                    </svg>
                </li>
                <li>
                    <ul class="aplayz_menu h_100per">
                        <li class="align_center ml_60px alarm_pc" @click="alarmMobile">
                            <img v-if="$store.state.noticeCount === 0" src="/media/img/alarm.png" alt="알림" />
                            <img v-else src="/media/img/alarm_icon_active.svg" alt="알림" />
                        </li>
                        <li class="align_center ml_60px alarm_mo" @click="alarmMobile">
                            <svg v-if="$store.state.noticeCount === 0" class="h_100per" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.02759 19.7773C9.02759 20.5181 9.46002 21.9996 11.1897 21.9996C12.9195 21.9996 13.3519 20.5181 13.3519 19.7773" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M2.00024 18.666H20.3786" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M3.62183 18.1113V10.3335C3.62183 7.92613 5.13534 3.11133 11.1894 3.11133C17.2434 3.11133 18.7569 8.2965 18.7569 10.8891V18.1113" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                <!---->
                            </svg>
                            <svg v-else class="h_100per" data-v-27bf496f="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path data-v-27bf496f="" d="M9.02783 19.7773C9.02783 20.5181 9.46026 21.9996 11.19 21.9996C12.9197 21.9996 13.3521 20.5181 13.3521 19.7773" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path data-v-27bf496f="" d="M2.00049 18.666H20.3788" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path
                                    data-v-27bf496f=""
                                    d="M3.62207 18.1113V10.3335C3.62207 7.92613 5.13558 3.11133 11.1896 3.11133C17.2436 3.11133 18.7572 8.2965 18.7572 10.8891V18.1113"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                ></path>
                                <ellipse data-v-27bf496f="" cx="17.6759" cy="6.44444" rx="4.32431" ry="4.44444" fill="#FF1150"></ellipse>
                            </svg>
                        </li>
                        <li class="align_center ml_60px mene_bar" @click="showAside = true">
                            <img class="cursor" src="/media/img/toggle.svg" alt="메뉴" />
                        </li>
                    </ul>
                </li>
            </ul>
        </header>
        <main class="w_100 relative hidden">
            <slot name="main"></slot>
        </main>
    </div>
</template>
<script>
import Aside from '@/layout/aside/Aside.vue';
export default {
  name: 'IntroSlot',
  components: {
    'aside-comp': Aside
  },
  data () {
    return {
      showAside: false
    };
  },

  mounted () {},

  methods: {
    onClickOutside () {
      this.showAside = false;
    },
    alarmMobile () {
      this.$router.push({ name: 'Notice0' });
    }
  }
};
</script>
<style scoped src="@/assets/css/introheader.css"></style>
