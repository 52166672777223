<template>
    <intro-slot>
        <template v-slot:main>
            <add-space v-if="showAddSpace" @closeAddSpace="showAddSpace = false" />
            <greet-modal v-if="isGreetModal" @closeModal="isGreetModal = false" />
            <img
                v-if="weatherInfo.id === 'afternoon' || weatherInfo.id === 'dinner' || weatherInfo.id === 'mornig'"
                class="aplayz_nomal_cloud normal_cloud2"
                src="/media/img/morning_cloud2.png"
                alt="구름"
            />
            <img
                v-if="weatherInfo.id === 'mornigRain' || weatherInfo.id === 'mornigCloud'"
                class="cloud cloud1"
                src="/media/img/cloud_morning.svg"
                alt="아침구름"
            />
            <img
                v-if="weatherInfo.id === 'mornigRain' || weatherInfo.id === 'mornigCloud'"
                class="cloud cloud2"
                src="/media/img/cloud_morning.svg"
                alt="아침구름"
            />
            <img
                v-if="weatherInfo.id === 'mornigRain' || weatherInfo.id === 'mornigCloud'"
                class="cloud cloud3"
                src="/media/img/cloud_morning.svg"
                alt="아침구름"
            />
            <img
                v-if="weatherInfo.id === 'mornigRain' || weatherInfo.id === 'mornigCloud'"
                class="cloud cloud4"
                src="/media/img/cloud_morning.svg"
                alt="아침구름"
            />

            <img
                v-if="weatherInfo.id === 'afternoonRain' || weatherInfo.id === 'afternoonCloud'"
                class="cloud cloud1"
                src="/media/img/cloud_afternoon.svg"
                alt="오후구름"
            />
            <img
                v-if="weatherInfo.id === 'afternoonRain' || weatherInfo.id === 'afternoonCloud'"
                class="cloud cloud2"
                src="/media/img/cloud_afternoon.svg"
                alt="오후구름"
            />
            <img
                v-if="weatherInfo.id === 'afternoonRain' || weatherInfo.id === 'afternoonCloud'"
                class="cloud cloud3"
                src="/media/img/cloud_afternoon.svg"
                alt="오후구름"
            />
            <img
                v-if="weatherInfo.id === 'afternoonRain' || weatherInfo.id === 'afternoonCloud'"
                class="cloud cloud4"
                src="/media/img/cloud_afternoon.svg"
                alt="오후구름"
            />

            <img
                v-if="weatherInfo.id === 'dinnerRain' || weatherInfo.id === 'dinnerCloud'"
                class="cloud cloud1"
                src="/media/img/cloud_dinner.svg"
                alt="저녁구름"
            />
            <img
                v-if="weatherInfo.id === 'dinnerRain' || weatherInfo.id === 'dinnerCloud'"
                class="cloud cloud2"
                src="/media/img/cloud_dinner.svg"
                alt="저녁구름"
            />
            <img
                v-if="weatherInfo.id === 'dinnerRain' || weatherInfo.id === 'dinnerCloud'"
                class="cloud cloud3"
                src="/media/img/cloud_dinner.svg"
                alt="저녁구름"
            />
            <img
                v-if="weatherInfo.id === 'dinnerRain' || weatherInfo.id === 'dinnerCloud'"
                class="cloud cloud4"
                src="/media/img/cloud_dinner.svg"
                alt="저녁구름"
            />

            <img
                v-if="weatherInfo.id === 'nightRain' || weatherInfo.id === 'nightCloud'"
                class="cloud cloud1"
                src="/media/img/cloud_eve.svg"
                alt="밤구름"
            />
            <img
                v-if="weatherInfo.id === 'nightRain' || weatherInfo.id === 'nightCloud'"
                class="cloud cloud2"
                src="/media/img/cloud_eve.svg"
                alt="밤구름"
            />
            <img
                v-if="weatherInfo.id === 'nightRain' || weatherInfo.id === 'nightCloud'"
                class="cloud cloud3"
                src="/media/img/cloud_eve.svg"
                alt="밤구름"
            />
            <img
                v-if="weatherInfo.id === 'nightRain' || weatherInfo.id === 'nightCloud'"
                class="cloud cloud4"
                src="/media/img/cloud_eve.svg"
                alt="밤구름"
            />
            <!--#### 비 ###-->
            <canvas width="1280" height="720" v-show="weatherInfo.weatherGb === 'rain'" id="raincanvas"></canvas>
            <!--### 눈 ###-->
            <particles-bg
                v-if="weatherInfo.weatherGb === 'snow'"
                ref="particlesbg"
                id="particles-js"
                type="custom"
                :config="config"
                :bg="true"
            />
            <div class="player_pc_main_box">
                <div class="player_main_full_box">
                    <div class="play_img_box">
                        <img class="play_img_box_img" src="/media/img/recording.png" alt="" />
                    </div>
                    <div class="play_main_full_txt">
                        <h1 class="play_main_full_txt_title">
                            스페이스를 등록하고<br />
                            <span class="play_main_full_txt_title_bold">공간에 어울리는 음악 큐레이션</span><br />
                            서비스를 받아보세요.
                        </h1>
                        <div class="play_regi_btn" @click="linkInsert">
                            <p class="play_regi_btn_left_box">
                                <span class="play_regi_btn_left_first">스페이스 등록하기</span> <br />
                                <span>1분이면 끝! 쉽고 간단하게 스페이스를 만들어보세요</span>
                            </p>
                            <img src="/media/img/btn_icon.png" alt="btn_icon" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="player_mo_main_box">
                <tutorial-comp v-if="tutorialStatus" @closeTutorial="tutorialStatus = false" />
                <div class="player_mo_main_wrap">
                    <h2 class="player_mo_main_txt_title">
                        스페이스를 등록하고 <br />
                        <span class="play_main_full_txt_title_bold">공간에 어울리는 음악 큐레이션 </span> <br />
                        서비스를 받아보세요.
                    </h2>
                    <div class="mo_space_upload_wrap">
                        <div class="mo_space_insert_wrap"></div>
                        <div class="mo_space_circle" @click="linkInsert">
                            <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.969727" y="13.5879" width="30.3333" height="3.01967" rx="1.50984" fill="white"></rect>
                                <rect
                                    x="17.6533"
                                    width="30.1967"
                                    height="3.03333"
                                    rx="1.51667"
                                    transform="rotate(90 17.6533 0)"
                                    fill="white"
                                ></rect>
                            </svg>
                            <p class="mo_space_upload_title">스페이스 등록</p>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </intro-slot>
</template>
<script>
import { defineComponent } from '@vue/composition-api';

import axios from 'axios';
import VueCookies from 'vue-cookies';
import dayjs from 'dayjs';
import IntroSlot from '@/slot/introSlot.vue';
import { getWeatherId, getWeather } from '@/utils/Utils';
import { rainAnimation } from '@/assets/js/rainAnimation';
import { ParticlesBg } from 'particles-bg-vue';

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
export default defineComponent({
  name: 'Main',
  data () {
    return {
      currentInfo: {},
      lon: null,
      lat: null,
      mode: '',
      isRegistCard: true,
      isGreetModal: false,
      weatherInfo: {
        id: 'afternoon',
        weatherGb: 'sunny'
      },
      tutorialStatus: false,
      showAddSpace: false,
      config: {
        num: 1,
        rps: -1,
        radius: [15, 15],
        life: [10, 10],
        v: 0.1,
        tha: [150, 200],
        alpha: [0.3, 1],
        scale: [0.1, 0.4],
        position: 'all',
        cross: 'dead',
        random: 10,
        color: '#ffffff',
        g: 0.5
      }
    };
  },
  components: {
    'intro-slot': IntroSlot,
    ParticlesBg,
    'add-space': () => import(`@/components/modal/Main/AddSpace.vue`),
    'greet-modal': () => import(`@/components/modal/Main/GreetModal.vue`),
    'tutorial-comp': () => import(`@/components/modal/Main/Tutorial.vue`)
  },

  created () {
    const check = localStorage.getItem('tutorialStatusMain');
    if (check !== 'Y') {
      this.tutorialStatus = true;
    }
    if (this.$store.state.signupCouponAt === 'Y') {
      this.isGreetModal = true;
    }
    this.modeCheck();
    this.checkRegistCard();
  },
  mounted () {
    this.checkEvent();
    // this.getCurrntTimesDays();
    this.getCurrentPosition();
    setTimeout(() => {
      this.getCurrentInfo();
    }, 1000);
  },
  methods: {
    async setBg () {
      document.body.id = this.weatherInfo.id;
    },
    checkRegistCard () {
      const userId = VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/product/getMyCardInfo', { userId }, { headers })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            result != null ? (this.isRegistCard = true) : (this.isRegistCard = false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    modeCheck () {
      var url = window.location.host;
      url.indexOf('localhost') !== -1 || url.indexOf('dev') !== -1 ? (this.mode = 'dev') : (this.mode = 'prod');
    },
    checkEvent () {
      // 이벤트 날짜 체크
      var userId = VueCookies.get('userId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/menu/getRecentEvent', { userId }, { headers })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            var result = res.data.result;
            var eventStartDate = result.eventStartDate;
            var eventEndDate = result.eventEndDate;
            // 현재날짜, 이벤트 시작/종료 날짜
            var today = dayjs();
            var startDate = dayjs(eventStartDate);
            var endDate = dayjs(eventEndDate);
            // 현재날짜와 이벤트 시작/종료 날짜의 차
            var startDay = startDate.diff(today, 'day', true);
            var endDay = endDate.diff(today, 'day', true);
            // 이벤트 시작 후 7일 경과 또는 이벤트가 종료되었을 경우
            Math.floor(startDay) < -7 || Math.floor(endDay) < 0
              ? this.$store.commit('setEventInfo', false)
              : this.$store.commit('setEventInfo', true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCurrentPosition () {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.lat = position.coords.latitude; // 위도
          this.lon = position.coords.longitude; // 경도
        },
        (error) => {
          var message = error.message;
          if (message === 'User denied Geolocation') {
            this.lat = 37.566826;
            this.lon = 126.9786567;
          } else {
            console.log(message);
          }
        }
      );
    },
    getCurrentInfo () {
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };
      let lat;
      let lon = '';
      if (this.lat == null) {
        lat = 37.566826;
        lon = 126.9786567;
      } else {
        lat = this.lat;
        lon = this.lon;
      }
      axios
        .post('/api/player/getCurrentTimesDays', { lon, lat }, { headers, cancelToken: source.token })
        .then((res) => {
          this.currentInfo = res.data.result;

          const weatherInfo = {
            id: getWeatherId(this.currentInfo.currentTimes, this.currentInfo.currentWeather),
            weatherGb: getWeather(this.currentInfo.currentWeather)
          };
          this.weatherInfo = weatherInfo;

          this.setBg();
          if (this.weatherInfo.weatherGb === 'rain') {
            this.$nextTick(() => {
              rainAnimation.init();
              rainAnimation.animate();
              window.addEventListener('resize', rainAnimation.resize.bind(rainAnimation));
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    linkInsert () {
      const spaceId = this.$cookies.get('spaceId');
      spaceId != null ? (this.showAddSpace = true) : this.$router.push({ name: 'SpaceInsert' }).catch(() => {});
    }
  },
  destroyed () {
    source.cancel('cancel getCurrentTimesDays');
    document.body.id = '';
  }
});
</script>
<style scoped src="@/assets/css/main.css"></style>
